.here-btn {
    width: 120px;
    background-color: #FBBD13;
    font-weight: bold;
    color: #01422A;
}

.here-btn:hover {
    background-color: #fba213;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {

    .video-container {
        position: relative;
        height: 300px;
        width: 100%;
    }

    iframe {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100px;
        min-width: 100%;
        height: 100px;
        min-height: 100%;
    }
}



.home {
    height: 40px;
}
