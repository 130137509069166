
.main {
    position: relative;
    width: 75%;
    height: 390px;
    border: 3px solid var(--color-accent);
    z-index: 10;
    font-weight: 900;
}

.main-text img {
    height: 14rem;
}

.contact-btn {
    background-color: #FBBD13;
    font-size: 18px;
    width: 140px;
    color: #01422A;
}

.quokka {
    border: 3px solid #01422A;
    border-radius: 50%;
    width: auto;
}

.half {
    position: absolute;
    left: 20px;
    top: 532px;
    transform: rotate(45deg);
}

.red-dot {
    position: absolute;
    right: 35px;
    top: 105px;
}


.message {
    min-width: 225px;
}

.message span {
    width: 300px;
    max-width: 300px;
    min-width: 300px;
}


@media only screen and (max-width: 768px) {
    .main {
        height: 320px;
    }

    .main-text img {
        height: 10rem;
    }

    .main-text {
        margin: 0 0 0 20;
    }

    .message {
        min-width: 225px;
    }

    .half {
        left: 12px;
        top: 466px;
    }
}

@media only screen and (max-width: 640px) {

    .main-text img {
        height: 8rem;
    }

    .half {
        left: unset;
        right: 14px;
        top: 390px;
        transform: rotate(0deg)
    }

    .red-dot {
        left: 20px;
        top: 15px;
        width: 120px;
    }
}

@media only screen and (max-width: 500px) {
    .main {
        height: 260px;
    }

    .main-text img {
        height: 6rem;
    }

    .main-text h2 {
        font-size: 20px;
    }

    .message {
        min-width: 150px;
    }
    .half {
        top: 335px;
    }
}

@media only screen and (max-width: 415px) {
    .main {
        margin-top: 30px;
    }

    .red-dot {
        top: 68px;
    }

    .half {
        top: 378px;
    }
}

#cursor {
    border-left: .1em solid #01422A;
    animation: blink .7s steps(1) infinite;
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}
