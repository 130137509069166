
body {
  margin: 0;
}

body {
  color: var(--font-color);
  background: var(--color-primary);
}

.App {
  text-align: center;
}

.card, .main{
  background-color: var(--color-secondary);
}

.form-card input, textarea {
  background-color: var(--input-color);
}

.mobile-nav {
  background: var(--color-primary);
  z-index: 100;
}

h1 {
  color: var(--font-second-color);
}

.theme-light {
  --color-primary: #FBF7EA;
  --color-secondary: #F4CEE2;
  --color-accent: #01422A;
  --font-color: #01422A;
  --font-second-color: #01422A;
  --input-color: #ffffff;
}

.theme-dark {
  --color-primary: #121212;
  --color-secondary: #464646;
  --color-accent: #01422A;
  --font-color: #FBF7EA;
  --font-second-color: #FBBD13;
  --input-color: #464646;
}
