.contact-btn {
    background-color: #FBBD13;
    font-size: 18px;
    width: 120px;
}

.nav-links ul{
    font-size: 18px;
}

.social-icon:hover {
    fill: #FBBD13;
}
