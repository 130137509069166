@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: font-url('assets/fonts/Poppins-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

body>#root>div {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
