
.projects-btn {
    width: 100px;
    background-color: #FBBD13;
    font-weight: bold;
    color: #01422A;
}

.projects-btn:hover {
    background-color: #fba213;
}

.card img {
    width: auto;
}
